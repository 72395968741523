import React, { useEffect, useState } from "react";
import Head from "../organisms/head/index";
import Body from "../organisms/body/index";

export default function Search() {
  const [searchWord, setsearchWord] = useState("");
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setsearchWord(params.s);
    return () => {};
  });
  const data ={
    title: searchWord
}
  return (
    <>
      <Head data={data} />
      <Body bodyData={{ type: "search", search: searchWord }} />
    </>
  );
}
